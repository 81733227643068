import { Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmploymentStatus from "./TabOne";
import CurrentTenancyInfo from "./TabThree";
import MeansOfIdentification from "./TabTwo";
import { otherInfoStyles } from "./otherInfoStyle";
import { useDispatch, useSelector } from "react-redux";
import { getOtherInformation } from "#/store/actions/auth.actions";
import { getApartmentById } from "#/store/actions/apartment.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const Tabs = styled("div")`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
`;

const Tab = styled("div")`
  max-width: 200px;
  padding: 10px;
  font-size: 13px;
  height: 100%;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 4px 18px -6px rgba(0, 0, 0, 0.25);

  .active {
    background: #10609f;
    color: #fff;
  }
`;

const active = {
  background: "#10609f",
  color: "#fff"
};

const OtherInformation = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const classes = otherInfoStyles();

  const apartment = useSelector((state) => state.tenant?.tenantsApartment?.apartment_id);
  const { apartmentById, loading } = useSelector((state) => state.apartment);
  const [propertyOwner, setPropertyOwner] = useState({});
  const [propertyManager, setPropertyManager] = useState({});

  console.log(apartment);

  useEffect(() => {
    if (apartment?.apartment_id) dispatch(getApartmentById(apartment?.apartment_id));
  }, [apartment?.apartment_id]);

  useEffect(() => {
    if (apartmentById?.owner) {
      propertyServiceApi()
        .get(`/property-owner/${apartmentById.owner}`)
        .then((res) => {
          setPropertyOwner(res.data.payload);
        });
    } else {
      setPropertyOwner(null);
    }
  }, [apartmentById]);

  useEffect(() => {
    if (apartmentById?.property_manager) {
      propertyServiceApi()
        .get(`/property-manager/${apartmentById.property_manager}`)
        .then((res) => {
          setPropertyManager(res.data.payload);
        });
    } else {
      setPropertyManager(null);
    }
  }, [apartmentById]);

  const handleTabChange = (index) => {
    setCurrentTab(index);
  };
  useEffect(() => {
    dispatch(getOtherInformation());
  }, [dispatch]);

  let tabComponent;
  switch (currentTab) {
    case 0:
      tabComponent = <EmploymentStatus />;
      break;
    case 1:
      tabComponent = <MeansOfIdentification />;
      break;
    case 2:
      tabComponent = (
        <CurrentTenancyInfo
          apartmentById={apartmentById}
          propertyManager={propertyManager}
          propertyOwner={propertyOwner}
          loading={loading}
        />
      );
      break;
    default:
      tabComponent = <EmploymentStatus />;
      break;
  }

  return (
    <>
      <div className={classes.root}>
        <Tabs>
          <Tab sx={currentTab === 0 && active} onClick={() => handleTabChange(0)}>
            Employment Status
          </Tab>
          <Tab sx={currentTab === 1 && active} onClick={() => handleTabChange(1)}>
            Means Of Identification
          </Tab>
          <Tab sx={currentTab === 2 && active} onClick={() => handleTabChange(2)}>
            Current Tenancy Info
          </Tab>
        </Tabs>
        <Container sx={{ margin: "90px auto" }}>{tabComponent}</Container>
      </div>
    </>
  );
};

export default OtherInformation;
