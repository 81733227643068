import { Button, Stack, styled, Modal, Box, Typography } from "@mui/material";
import WhatsappLinkIcon from "#/components/link-icons/WhatsappLinkIcon";
import CustomBtn from "#/components/buttons/CustomBtn";
import { useSelector } from "react-redux";
import { selectRentWallet } from "#/store/actions/izypay.actions";
import { BiSolidDownload } from "react-icons/bi";
import React, { useState } from "react";

const MonthlyRentStatusActions = ({
  stageOne,
  stageTwo,
  review_stage_1,
  laid,
  goToForm,
  goToRenew,
  goToActivation,
  goToAgreements,
  REVIEW,
  VERIFIED,
  ACCEPTED,
  NONE,
  view,
  renewal,
  data
}) => {
  const rentWallet = useSelector(selectRentWallet);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleConfirm = () => {
    window.open(rentWallet?.wallet?.validation_url, "_blank");
    setIsModalOpen(false);
  };

  const monthly_rent_bills = data?.data?.filter((bill) => bill.name === "Monthly Rent");

  const renderTenantActions = (renewal) => {
    switch (true) {
      case stageOne === VERIFIED && !laid:
        return (
          <VerifySection>
            <em>
              "To proceed to the final stage to activate your monthly plan, please get
              verified by your Landlord or Caretaker now".
            </em>
            <p>How to get verified? Use the icons below</p>
            <Stack direction="row" spacing={2} mt={1}>
              <WhatsappLinkIcon />
            </Stack>
          </VerifySection>
        );
      case stageOne === REVIEW:
        return (
          <>
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: "90%"
              }}>
              "{review_stage_1}"
            </p>
            <Button variant="outlined" onClick={goToForm}>
              REVIEW PRE-QUALIFICATION
            </Button>
          </>
        );
      case stageOne === VERIFIED && laid && stageTwo === NONE:
        return (
          <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
            <p className="tw-text-[15px] tw-text-[#C8202D] tw-font-medium tw-mb-3">
              Please, click the button below to upload the signed copy of your monthly
              rent agreement
            </p>
            <CustomBtn onClick={goToAgreements}>Continue To Agreements</CustomBtn>
            <button className="tw-flex tw-justify-center tw-items-center tw-mt-10 tw-gap-3 w-full">
              <p className="tw-text-[#10609F] tw-text-[15px] tw-font-medium">
                Download Monthly Rent Agreement Here
              </p>
              <BiSolidDownload size={30} color="#10609F" />
            </button>
          </div>
        );
      case renewal?.adminStatus &&
        renewal?.renewalStatus &&
        renewal?.is_completed === "ongoing":
        return (
          <div className="tw-text-center">
            <CustomBtn
              onClick={() => {
                // console.log("Your pops dey ment, work joor :(");
                goToRenew();
              }}>
              Activate Monthly Rent Renewal
            </CustomBtn>
          </div>
        );
      case stageOne === VERIFIED && laid && stageTwo === ACCEPTED:
        if (monthly_rent_bills?.length > 0) {
          return null;
        } else {
          if (rentWallet?.wallet.status === "pnd") {
            return (
              <div className="tw-text-center">
                <CustomBtn onClick={handleOpenModal} style={{ cursor: "pointer" }}>
                  Activate Monthly Plan
                </CustomBtn>
              </div>
            );
          } else {
            return (
              <div className="tw-text-center">
                <CustomBtn onClick={goToActivation}>Activate Monthly Plan</CustomBtn>
              </div>
            );
          }
        }
      default:
        return null;
    }
  };

  const renderNonTenantActions = () => {
    switch (true) {
      case stageOne === REVIEW:
        return (
          <>
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: "90%"
              }}>
              "{review_stage_1}"
            </p>
            <Button variant="outlined" onClick={goToForm}>
              REVIEW PRE-QUALIFICATION
            </Button>
          </>
        );
      case stageOne === VERIFIED && !laid:
        return (
          <VerifySection>
            <em>
              "To proceed to the final stage to activate your monthly plan, please get
              verified by your Landlord or Caretaker now".
            </em>
            <p>How to get verified? Use the icons below</p>
            <Stack direction="row" spacing={2} mt={1}>
              <WhatsappLinkIcon />
            </Stack>
          </VerifySection>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {view === "tenant" ? renderTenantActions(renewal) : renderNonTenantActions()}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4
          }}>
          <Typography variant="h6" component="h2">
            Your virtual account has to be activated!
          </Typography>
          <Typography sx={{ mt: 2 }}>Please fill consent form now</Typography>
          <Button
            onClick={handleConfirm}
            sx={{ mt: 2 }}
            style={{ color: "#10609F", textDecoration: "underline" }}>
            Activate LR Account
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MonthlyRentStatusActions;

const VerifySection = styled("section")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  gap: 0.7rem;
  margin-top: 1rem;

  em {
    color: #ff0000;
    word-wrap: break-word;
    text-align: center;
  }

  p {
    color: #052137;
    font-weight: bold;
  }
`;
