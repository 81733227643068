import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRenderWidgets } from "#/hooks/useRenderWidget";
import useFetch from "#/hooks/util-hooks/useFetch";
import { izyBizApi } from "#/apis/propertyServiceApi";
import { CircularProgress } from "@material-ui/core";

const NewProperties = () => {
  const user = useSelector((state) => state.auth.user);
  const { liveizy_id } = user;

  const { data: business } = useFetch(`/admin/primary-business`, izyBizApi);
  const [portfolioData, setPortfolioData] = useState(null);

  useEffect(() => {
    if (business?.data?.id) {
      setPortfolioData({
        identifier: liveizy_id,
        role: "admin",
        component: "",
        lid: liveizy_id,
        business: business.data.id
      });
    }
  }, [business, liveizy_id]);

  useRenderWidgets(".portfolio", window.renderPortfolioWidget);

  if (!portfolioData)
    return (
      <div className="tw-flex tw-justify-center tw-flex-col tw-gap-10 tw-mt-32">
        <CircularProgress />
        <p>Loading Widget...</p>
      </div>
    ); // Wait until data is ready

  return <div className="portfolio" data-portfolio={JSON.stringify(portfolioData)} />;
};

export default NewProperties;
