import { toCurrency } from "#/components/utils/util";
import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import PaperContainer from "../../paperContainer/Index";
import { removeNonNumeric } from "#/shared/utils/charformat/digitFormart";
import { useSelector } from "react-redux";
import { izyBizApi, propertyServiceApi } from "#/apis/propertyServiceApi";
import LoadingModal from "#/components/loadingModal/LoadingModal";

const LoanBillView = ({ contribution, securityDeposit, currentRenewal, lid, amount }) => {
  const [loading, setLoading] = useState(true);
  const [loanValues, setLoanValues] = useState({
    values: false,
    monthly_payment: 0,
    subscription_fee: 0,
    security_deposit: 0,
    total_first_payment: 0,
    contribution: 0
  });

  const { tenantRecord } = useSelector((store) => store.tenant);
  const apartmentId = tenantRecord[0]?.apartment_id.apartment_id;

  useEffect(() => {
    if (!apartmentId) return;
    getLoanBill();
  }, [contribution, apartmentId]);

  function getLoanBill() {
    setLoading(true);
    izyBizApi()
      .post(
        `/miscellenous/calculate-renew-loan${
          currentRenewal?.data?.isSecondRenewal ? "?secondRenewal=yes" : ""
        }`,
        {
          amount: amount,
          contribution: contribution,
          securityDeposit: securityDeposit,
          lid: lid
        }
      )
      .then((res) => {
        const {
          monthly_payment,
          subscription_fee,
          security_deposit,
          total_first_payment,
          contribution
        } = res?.data?.data;

        setLoanValues((prev) => ({
          ...prev,
          values: true,
          monthly_payment,
          subscription_fee,
          security_deposit,
          total_first_payment,
          contribution
        }));
        setLoading(false);
      })
      .catch(function (error) {
        setLoanValues((prev) => ({
          ...prev,
          values: false
        }));
        setLoading(false);
        return Promise.reject(error);
      });
  }
  return (
    <>
      {loading && <LoadingModal text={"Calculating Bill..."} />}
      <Wrapper>
        <FirstBillCard
          loanValues={loanValues}
          contribution={contribution}
          securityDeposit={securityDeposit}
          showTotal={true}
        />
        {currentRenewal?.data?.isSecondRenewal && (
          <InfoText>
            This is your Second-Year Renewal.
            <br />
            Your first and second payments have been covered by your Security Deposit. You
            only need to pay the subscription fee for this renewal.
          </InfoText>
        )}

        {/* <InfoText>
          Please, confirm the the payment above is correct, once you confirm an invoice
          will be generated for you to complete your first payment.
          <br />
          Once your renewal payment is made, the complete rent will be paid on the rent
          payment date to the rent account details provided by your caretaker or Landlord
        </InfoText> */}
      </Wrapper>
    </>
  );
};

export default LoanBillView;

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 0.75rem;
  width: 100%;
`;

const BillCard = styled(PaperContainer)`
  max-width: 520px;
  width: 100%;
  height: 100%;
  color: rgba(5, 33, 55, 0.8);
  font-size: 0.85rem;
  font-weight: 600;
  display: grid;
  gap: 1rem;
`;

const CardLine = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;

const FeeText = styled("div")`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    min-width: 14ch;
    width: 100%;
    font-weight: 500;
    text-align: left;
  }
`;

const InfoIcon = styled(BsFillInfoCircleFill)`
  color: #31589e;
  text-align: right;
`;

const DottedLine = styled("hr")`
  border-top: 4px dashed red;
  max-width: 250px;
  width: 100%;
`;

const FeeAmount = styled("p")`
  flex: 1;
`;

const InfoText = styled("p")`
  color: #c8202d;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  max-width: 81ch;
`;

export const FirstBillCard = ({
  loanValues,
  contribution,
  showTotal,
  securityDeposit
}) => {
  return (
    <BillCard>
      <CardLine>
        <FeeText>
          <p>Subscription Fee</p>
          <InfoIcon />
        </FeeText>
        <DottedLine />
        <FeeAmount>
          {toCurrency(removeNonNumeric(loanValues?.subscription_fee)) || 0}
        </FeeAmount>
      </CardLine>

      {/* Security Deposit */}
      <CardLine>
        <FeeText>
          <p>Security Deposit</p>
          <InfoIcon />
        </FeeText>
        <DottedLine />
        <FeeAmount>{toCurrency(removeNonNumeric(securityDeposit) || 0)}</FeeAmount>
      </CardLine>

      <CardLine>
        <FeeText>
          <p>Monthly Rent</p>
          <InfoIcon />
        </FeeText>
        <DottedLine />
        <FeeAmount>
          {toCurrency(removeNonNumeric(loanValues?.monthly_payment)) || 0}
        </FeeAmount>
      </CardLine>

      <CardLine>
        <FeeText>
          <p>Contribution</p>
          <InfoIcon />
        </FeeText>
        <DottedLine />
        <FeeAmount>{toCurrency(removeNonNumeric(contribution) || 0)}</FeeAmount>
      </CardLine>

      {showTotal && (
        <>
          <div style={{ width: "100%", borderBottom: "1px solid grey" }}></div>

          <CardLine>
            <FeeText>
              <p style={{ fontWeight: "bold", width: "16.5ch" }}>Total First Payment</p>
            </FeeText>
            <DottedLine />
            <FeeAmount style={{ fontWeight: "bolder", color: "red", fontSize: "0.9rem" }}>
              {toCurrency(removeNonNumeric(loanValues?.total_first_payment))}
            </FeeAmount>
          </CardLine>
        </>
      )}
    </BillCard>
  );
};
