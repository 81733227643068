import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Dialog, styled } from "@mui/material";
import ContributionView from "./ContributionView";
import LoanBillView from "./LoanBillView";
import CustomBtn from "#/components/buttons/CustomBtn";
import { izyBizApi, propertyServiceApi } from "#/apis/propertyServiceApi";
import { removeNonNumeric } from "#/shared/utils/charformat/digitFormart";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import useFetch from "#/hooks/util-hooks/useFetch";
import { APP_ROUTES } from "#/constants/routes";

function renderView(
  view,
  contribution,
  setContribution,
  securityDeposit,
  currentRenewal,
  lid,
  amount
) {
  switch (view) {
    case 0:
      return (
        <ContributionView contribution={contribution} setContribution={setContribution} />
      );
    case 1:
      return (
        <LoanBillView
          contribution={contribution}
          securityDeposit={securityDeposit}
          currentRenewal={currentRenewal}
          lid={lid}
          amount={amount}
        />
      );

    default:
      break;
  }
}

const RenewMonthlyRent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const [contribution, setContribution] = useState(0);
  const [securityDepo, setSecurityDepo] = useState(0);
  const [calculatedData, setCalculatedData] = useState({});

  const user = useSelector((state) => state.auth.user);
  const { liveizy_id } = user;

  const { tenantRecord } = useSelector((store) => store.tenant);
  const apartmentId = tenantRecord[0]?.apartment_id.apartment_id;

  // Fetch current renewal data
  const { data: currentRenewal, loading: isLoading } = useFetch(
    `/current-renewal/${liveizy_id}`,
    {
      errorMessage: "Error getting data"
    }
  );

  const { data: business } = useFetch(`/admin/primary-business`, izyBizApi);

  const { data: tenant } = useFetch(`tenant/${liveizy_id}`, izyBizApi);

  const houseRentBill = tenant?.data?.houseRentBill;

  // Call loan calculate API
  const getLoanBill = async () => {
    if (!currentRenewal) return;
    setContribution(currentRenewal?.data?.contributionFee);
    setSecurityDepo(currentRenewal?.data?.securityDeposit);

    try {
      const response = await izyBizApi().post(
        `/miscellenous/calculate-renew-loan${
          currentRenewal?.data?.isSecondRenewal ? "?secondRenewal=yes" : ""
        }`,
        {
          amount: houseRentBill?.total_amount,
          contribution: contribution,
          securityDeposit: securityDepo,
          lid: liveizy_id
        }
      );

      setCalculatedData((prev) => ({ ...prev, ...response?.data?.data }));

      console.log(calculatedData);
    } catch (error) {
      console.error("Error calculating loan:", error);
      setCalculatedData((prev) => ({ ...prev, values: false }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!apartmentId || !currentRenewal) return;
    getLoanBill();
  }, [apartmentId, contribution, currentRenewal]);

  const renewLoanRentBills = async () => {
    setLoading(true);
    try {
      if (currentRenewal?.data?.isSecondRenewal) {
        await propertyServiceApi().post("/bill/loan_rent/second-renewal", {
          property: tenantRecord[0]?.property,
          apartment_id: tenantRecord[0]?.apartment_id.apartment_id,
          contribution: Number(removeNonNumeric(contribution)),
          security_deposit: securityDepo
        });
      } else {
        await izyBizApi().post("/liveizy-bill/renew-loan-record", {
          lid: calculatedData?.lid,
          type: "Monthly Rent",
          intialAmount: calculatedData?.amount,
          securityDepoist: calculatedData?.security_deposit,
          monthlyPayment: calculatedData?.monthly_payment,
          subscriptionFee: calculatedData?.subscription_fee,
          totalFirstMonthPayment: calculatedData?.total_first_payment,
          contributionFee: calculatedData?.contribution,
          bankLoanAmount: calculatedData?.bank_loan_size,
          apartmentId: tenantRecord[0]?.apartment_id.apartment_id,
          businessId: business?.data?.id

          // property: tenantRecord[0]?.property,
          // apartment_id: tenantRecord[0]?.apartment_id.apartment_id,
          // contribution: Number(removeNonNumeric(contribution)),
          // lid: tenantRecord[0]?.user_id,
          // security_deposit: securityDepo,
        });
      }

      dispatch(alertSuccess("Loan bills generated successfully"));

      await propertyServiceApi().patch(`/renewal-status`);

      history.push(APP_ROUTES.dashboard.main);
    } catch (error) {
      // Handle any error from either API call
      setLoading(false);
      dispatch(alertError(error.response.data?.error || "An Error occurred"));
    }
  };

  const handleNext = () => {
    if (view === 0) {
      setView((view) => view + 1);
    } else if (view === 1) {
      renewLoanRentBills();
    }
  };

  const handleBack = () => {
    if (view === 0) {
      history.push(APP_ROUTES.dashboard.main);
      setView((view) => view + 1);
    } else if (view === 1) {
      setView((view) => view - 1);
    }
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-20">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      {loading && <GenInvoiceModal />}
      <Wrapper>
        {renderView(
          view,
          contribution,
          setContribution,
          securityDepo,
          currentRenewal,
          liveizy_id,
          houseRentBill?.total_amount
        )}
        <ActionBtns>
          <Button sx={btnStyle} variant="outlined" color="primary" onClick={handleBack}>
            Back
          </Button>
          <CustomBtn sx={btnStyle} id="rent-savings-btn2" onClick={handleNext}>
            {view === 0 ? "Next" : "Confirm"}
          </CustomBtn>
        </ActionBtns>

        <VfdLogo>
          <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
        </VfdLogo>
      </Wrapper>
    </>
  );
};

export default RenewMonthlyRent;

const btnStyle = {
  fontSize: "0.75rem",
  padding: "0.4rem 3.4rem",
  border: "2px solid"
};

const Wrapper = styled("div")`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

const VfdLogo = styled("div")`
  margin: 0 auto;
  max-width: 200px;

  img {
    width: 100%;
  }
`;

const ActionBtns = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const GenInvoiceModal = () => {
  return (
    <Dialog open={true} fullWidth>
      <ModalWrapper>
        <CircularProgress size="6rem" />
        <h4>Generating Monthly Bill...</h4>
      </ModalWrapper>
    </Dialog>
  );
};

const ModalWrapper = styled("div")`
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  h4 {
    color: #052137;
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
`;
