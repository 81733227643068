import { makeStyles } from "@material-ui/core/styles";
import LoginBg from "../../../assets/images/login-bg.jpg";

export const loginStyles = makeStyles((theme) => ({
  loginBox: {
    backgroundColor: "#F5F6F9",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%"
  },
  loginBoxRegister: {
    backgroundColor: "#F5F6F9",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // height: "100vh",
    width: "100%"
  },
  quoteBox: {
    width: "18rem",
    height: "10rem",
    backgroundColor: "#FFF",
    position: "absolute",
    bottom: "1rem",
    left: "18rem",
    padding: "1.5rem",
    borderRadius: "0rem 0rem 4rem 0rem",
    [theme.breakpoints.only("xl")]: {
      left: "18rem"
    },
    [theme.breakpoints.only("lg")]: {
      left: "18rem"
    },
    [theme.breakpoints.only("md")]: {
      left: "10rem"
    }
  },
  loginSide: {
    backgroundImage: `url(${LoginBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh"
  },
  formContainer: {
    justifyContent: "center"
  },
  quote: {
    backgroundColor: "#043B5C",
    textAlign: "center",
    marginTop: "-2rem",
    marginLeft: "-3rem",
    width: "3rem",
    padding: "0.2rem"
  },
  quoteWriteUp: {
    fontSize: "0.8rem",
    fontStyle: "italic",
    color: "#043B5C"
  },
  position: {
    fontSize: "0.5rem",
    color: "#043B5C"
  },
  quoteName: {
    fontSize: "0.8rem",
    color: "#043B5C"
  },
  placement: {
    display: "flex",
    justifyContent: "space-between"
  },
  // form: {
  //   [theme.breakpoints.only("xl")]: {
  //     width: "70%"
  //   },
  //   [theme.breakpoints.only("lg")]: {
  //     width: "70%"
  //   },
  //   [theme.breakpoints.only("md")]: {
  //     width: "70%"
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     padding: "1.5rem"
  //   }
  // },
  registrationForm: {
    [theme.breakpoints.only("xl")]: {
      width: "70%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "70%"
    },
    [theme.breakpoints.only("md")]: {
      width: "70%"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem"
    }
  },
  registrationBox: {
    backgroundColor: "#F5F6F9",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%"
  },
  registrationLogo: {
    marginBottom: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem"
    }
  }
}));
