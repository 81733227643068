import FlexColumnMediaQuery from "#/components/flexboxes/FlexColumnMediaQuery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "#/constants/routes";

import PaperContainer from "../paperContainer/Index";
import { Button, styled } from "@mui/material";
import { showNotifications } from "#/store/actions/notification.actions";
import { useDispatch } from "react-redux";

const MonthlyRent = ({ preference }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateForm = () => {
    preference?.data?.bill_preference !== "annual_payment"
      ? history.push(APP_ROUTES.dashboard.monthlyRentForm)
      : dispatch(showNotifications("You cannot start Monthly rent"));
  };

  return (
    <FlexColumnMediaQuery sx={{ gap: "1rem" }}>
      <Block>
        <p>
          Rent payment is now easier & rewarding.
          <br />
          Build your credit score faster with our monthly rent plan
        </p>
        <h6>MONTHLY RENT</h6>
        <Button variant="outlined" onClick={navigateForm}>
          START PRE-QUALIFICATION
        </Button>
        <VfdLogo>
          <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
        </VfdLogo>
      </Block>
    </FlexColumnMediaQuery>
  );
};

export default MonthlyRent;

const Block = styled(PaperContainer)`
  display: flex;
  width: 60%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  font-weight: bold;
  gap: 7px;
  padding: 1.3rem 0;

  p {
    text-align: center;
    width: 80%;
    word-wrap: break-word;
  }

  h6 {
    font-weight: bold;
    color: #10609f;
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const VfdLogo = styled("div")`
  margin: 0 auto;
  max-width: 200px;

  img {
    width: 100%;
  }
`;
