import { izyBizApi } from "#/apis/propertyServiceApi";
import { useRenderWidgets } from "#/hooks/useRenderWidget";
import useFetch from "#/hooks/util-hooks/useFetch";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const NewTenantInfo = () => {
  const user = useSelector((state) => state.auth.user);
  const { liveizy_id } = user;

  const { data: business } = useFetch(`/admin/primary-business`, izyBizApi);
  const [tenantData, setTenantData] = useState(null);

  // Set the state only when business is available
  useEffect(() => {
    if (business?.data?.id) {
      setTenantData({
        role: "admin",
        component: "",
        business: business?.data?.id,
        identifier: liveizy_id
      });
    }
  }, [business, liveizy_id]);

  useRenderWidgets(".tenant", window.renderTenantWidget);

  if (!tenantData)
    return (
      <div className="tw-flex tw-justify-center tw-flex-col tw-gap-10 tw-mt-32">
        <CircularProgress />
        <p>Loading Widget...</p>
      </div>
    ); // Wait until business data is available

  return (
    <div>
      <div className="tenant" data-tenant={JSON.stringify(tenantData)} />
    </div>
  );
};

export default NewTenantInfo;
