import { useEffect } from "react";

export const useRenderWidgets = (selector, renderFn) => {
  useEffect(() => {
    const widgetDivs = document.querySelectorAll(selector);

    // console.log("Initial widget divs:", widgetDivs); // Debugging

    if (widgetDivs.length > 0 && renderFn) {
      widgetDivs.forEach((div) => {
        try {
          renderFn(div);
        } catch (error) {
          console.error(`Error rendering widget for selector: ${selector}`, error);
        }
      });
    }

    const observer = new MutationObserver(() => {
      const newWidgetDivs = document.querySelectorAll(selector);

      // console.log("New widget divs found:", newWidgetDivs); // Debugging

      if (newWidgetDivs.length > 0 && renderFn) {
        newWidgetDivs.forEach((div) => {
          try {
            renderFn(div);
          } catch (error) {
            console.error(`Error rendering widget for selector: ${selector}`, error);
          }
        });
        observer.disconnect(); // Stop observing once rendered
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect(); // Cleanup on unmount
  }, [selector, renderFn]);
};
