/* eslint-disable no-useless-constructor */
import MetisMenu from "@metismenu/react";
// import "metismenujs/dist/metismenujs.css";
// images
import Logo from "#/assets/liveizylogos.png";
import LogoSmall from "#/assets/liveizy-logo-collasped.png";
import { FaTimes } from "react-icons/fa";

// import { generalSidebar, propertyManagerSidebar } from "./data";

import { logout } from "#/store/actions/auth.actions";

import "./sidebar.styles.scss";
import { connect } from "react-redux";
import { setToLocalStorage } from "../utils/util";
import ImageLoader from "../ui-components/image-component";
import { withRouter } from "react-router-dom";
import avatar from "#/assets/images/tenant/default-avatar.png";

import { GeneralSidebar, PropertyManagerSidebar } from "./sidebar-links";
import useWindowSize from "react-use/lib/useWindowSize";
import { useRef } from "react";

const Sidebar = (props) => {
  const windowSize = useWindowSize();

  // const activeSide = getFromLocalStorage("active");
  // const [active, setActive] = useState(
  //   () => getFromLocalStorage("active") || "DASHBOARD"
  // );
  // const { pathname } = useLocation();
  const sideRef = useRef();
  // const { isMobile } = useMobile();
  // const windowSize = useWindowSize();

  const ActiveToggle = (data) => {
    setToLocalStorage("active", data);
  };

  const sampleContainer = {
    maxHeight: "440px"
  };

  let Display = <GeneralSidebar ActiveToggle={ActiveToggle} />;

  //SideBar Links for Property Manager
  const { roles: role } = props.user;
  if (role !== undefined && role?.includes("Landlord")) {
    Display = <PropertyManagerSidebar ActiveToggle={ActiveToggle} />;
  }

  if ((role !== undefined && role?.includes("Agent")) || role?.includes("Landlord")) {
    Display = <PropertyManagerSidebar ActiveToggle={ActiveToggle} />;
  }

  const { first_name, last_name, liveizy_id, profile_image } = props.user;

  //---------------------------------------------------------------
  //This is to close the sidbar on mobile screen.
  //---------------------------------------------------------------
  // React.useEffect(() => {
  //   function handler(event) {
  //     if (props.mobile === false) return;
  //     if (isMobile || (windowSize.width < 969 && props.mobile)) {
  //       console.log("Here are the props of mobile", props.mobile);
  //       if (!sideRef.current?.contains(event.target)) {
  //         //props.setMobileToggle(false); This blocks the opening of the sidebar on mobile
  //       }
  //     }
  //   }
  //   window.addEventListener("click", handler);

  //   //clean up
  // }, [isMobile, props.mobile]);

  // React.useEffect(() => {
  //   if (isMobile || windowSize.width < 969) {
  //     props.setMobileToggle(false);
  //   }
  // }, [pathname]);

  return (
    // the zIndex bellow was set bcos of the zIndex set on the card wrapping tenants apartment
    <>
      {/* Sidebar  */}
      <nav
        ref={sideRef}
        className={`sidebar ${props.sideBarToggled ? "sidebarToggled" : ""}`}>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-9 tw-gap-7">
          {windowSize.width <= 800 && (
            <div className="border-2 border-gray-200 p-3 rounded-full">
              <FaTimes
                size={20}
                onClick={() => props.setToggleSideBar(!props.sideBarToggled)}
              />
            </div>
          )}
          <div className="sidebar-header">
            <ImageLoader
              src={Logo}
              alt="liveizy_logo"
              className="logo logo-lg"
              style={{ maxWidth: "110px" }}
            />
            <ImageLoader
              src={LogoSmall}
              alt="liveizy_logo"
              className="logo logo-sm"
              style={{ maxWidth: "30px" }}
            />
          </div>

          <div className="img-contain">
            {profile_image == null ? (
              <img src={avatar} alt="user" />
            ) : (
              <img
                src={profile_image}
                alt="tenants"
                className="rounded-full w-48 h-24 overflow-hidden text-gray-400  rounded-xl"
              />
            )}{" "}
          </div>
          <div className="user-info tw-text-center">
            <p className="">
              {" "}
              LID - <span className="user-id text-center">{liveizy_id}</span>{" "}
            </p>
            <p className="text-center" style={{ marginBottom: "5px" }}>
              {" "}
              {`${first_name} ${last_name}`}
            </p>
          </div>
        </div>

        {/**SideBar routes */}
        <div
          className="tw-mt-3"
          style={sampleContainer}
          options={{ suppressScrollX: true, wheelPropagation: false }}>
          <div className="md:pl-5 md:pr-2">
            <MetisMenu>{Display}</MetisMenu>
          </div>
        </div>
      </nav>

      {/* Page Content  */}
    </>
  );
};

const mapStateToProps = (state) => ({
  logout2: state.auth.logout,
  user: state.auth.user,
  role: state.auth.role
});
export default connect(mapStateToProps, { logout })(withRouter(Sidebar));
