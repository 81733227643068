import React from "react";
import ReactDOM from "react-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import store from "./store/store";
import { logout, saveToken } from "./store/actions/auth.actions";

const userData = JSON.parse(localStorage.getItem("data"));
let token = userData ? userData.token : "";

// Add auth token manually before making any axios requests
// axios.interceptors.request.use(
//   (config) => {
//     if (!config.headers.Authorization && token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // handle Unauthorized error
//     if (error.response && error.response.status === 401) {
//       store.dispatch(logout()); // dispatch the logout action
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

// Add user profile data and token to state on App render
if (userData !== undefined && userData !== null) {
  store.dispatch(saveToken(token, userData.user));
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
