import React from "react";
// Icons
import { getFromLocalStorage } from "../utils/util";
import useWindowSize from "react-use/lib/useWindowSize";
import { useLocation } from "react-router-dom";
import { logout } from "#/store/actions/auth.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RiMenu4Fill } from "react-icons/ri";
import { IoIosNotificationsOutline } from "react-icons/io";
import { AiOutlinePoweroff } from "react-icons/ai";

const Header = (props) => {
  const windowSize = useWindowSize();
  const [headerName, setHeader] = React.useState("DASHBOARD");
  const { pathname } = useLocation();

  React.useEffect(() => {
    const headerName = getFromLocalStorage("active");
    if (headerName) {
      setHeader(headerName);
    }
  }, [pathname]);

  const logout = () => {
    const { logout } = props;
    logout(true);
  };

  const handleSideBar = (e) => {
    if (windowSize.width <= 800) {
      console.log("Mobile things");
      document.body.classList.remove("collapsed");
      e.stopPropagation();
      props.setToggleSideBar(true);
    } else {
      document.body.classList.toggle("collapsed");
    }
  };

  return (
    <div style={{}}>
      <div style={{ marginBottom: "35px" }}>
        <div className="tw-flex tw-justify-between tw-items-center">
          {/* <img
            src="/assets/dashboard/toggle.svg"
            width="20px"
            id="sidebarCollapse"
            alt="toggle-icon"
            style={{
              fontSize: 40,
              color: "#043B5C",
              cursor: "pointer",
              zIndex: 99999,
              position: "absolute"
            }}
            onClick={() => props.clickToggle()}
          /> */}
          <div className="tw-flex tw-items-center">
            <RiMenu4Fill
              className="cursor-pointer"
              size={35}
              color="#10609F"
              onClick={(e) => {
                handleSideBar(e);
              }}
            />
            <span style={{ marginLeft: "10px", position: "relative", left: "20px" }}>
              {headerName === null ? "DASHBOARD" : headerName}
            </span>
          </div>

          {true && (
            <React.Fragment>
              {/* <span className="float-right" style={{ marginRight: "30px" }}>
                                {first_name}
                            </span> */}
              {/* windowSize.width > 768 */}
              {/* <span onClick={() => logout()}>
                <img
                  src="/assets/dashboard/logout.svg"
                  width="20px"
                  className="float-right"
                  alt="notification svg"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              </span>
              <img
                src="/assets/dashboard/notify.svg"
                width="20px"
                className="float-right"
                alt="notification svg"
                style={{ marginRight: "30px" }}
              /> */}
              <div className="tw-flex tw-items-center tw-space-x-4">
                <IoIosNotificationsOutline
                  color="#10609F"
                  size={27}
                  className="cursor-pointer"
                />
                <AiOutlinePoweroff
                  className="cursor-pointer"
                  color="#10609F"
                  size={23}
                  onClick={() => logout()}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logout2: state.auth.logout,
  user: state.auth.user,
  role: state.auth.role
});
export default connect(mapStateToProps, { logout })(withRouter(Header));
