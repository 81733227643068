import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../store/store";
import { saveToken } from "../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { CircularProgress } from "@mui/material";

let token = "";
let auth = JSON.parse(localStorage.getItem("data"));
let user;
if (auth !== undefined && auth !== null) {
  token = auth.token;
  user = auth.user;
  // store.dispatch(saveToken(token, user));
}
// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// store.dispatch(getIzyPayBalance());

export const PrivateRouter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return window.localStorage.getItem("data") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

export const LandLordRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (window.localStorage.getItem("data") &&
        window.JSON.parse(localStorage.getItem("data"))["user"]["roles"].includes(
          "Landlord"
        )) ||
        window.JSON.parse(localStorage.getItem("data"))["user"]["roles"].includes(
          "Agent"
        ) ||
        window.JSON.parse(localStorage.getItem("data"))["user"]["roles"].includes(
          "Super Admin"
        ) ||
        window.JSON.parse(localStorage.getItem("data"))["user"]["roles"].includes(
          "Tenant"
        ) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/dashboard" />
      );
    }}
  />
);

// let role = window.localStorage.getItem("data") && window.JSON.parse(localStorage.getItem("data"))["user"]["role"];

// export const GeneralRoute = ({ component: Component, ...rest }) => (
//     <Route
//         {...rest}
//         render={(props) => {
//             if (role !== null && role !== "Landlord") {
//                 return <div>{<Component {...props} />}</div>;
//             } else if (role === null || role === undefined) {
//                 return <Redirect to="/" />;
//             } else if (role !== null && role.includes("Landlord")) {
//                 return <Redirect to="/pm/dashboard" />;
//             } else return;
//         }}
//     />

// )
// export const GeneralRoute = ({ component: Component, ...rest }) => (
//     <Route
//         {...rest}
//         render={(props) => {
//             if (window.localStorage.getItem("data") && role.length < 0) {
//                 return <Component {...props} />;
//             }
//         }}
//     />
// );

export const ActivePlanRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return window.localStorage.getItem("activeplan", true) ? (
        <div>{<Component {...props} />}</div>
      ) : (
        <Redirect to="/izyinvest" />
      );
    }}
  />
);

export const Verified = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return window.localStorage.getItem("data") &&
        window.JSON.parse(localStorage.getItem("data"))["user"]["is_email_verified"] ? (
        <div>{<Component {...props} />}</div>
      ) : (
        <Redirect to="/wallet" />
      );
    }}
  />
);

const REJECTED = "rejected";
const VERIFIED = "verified";
const ACCEPTED = "accepted";
const REVIEW = "review";
const PENDING = "pending";
const NONE = "none";

export const TenantRoute = ({ component: Component, ...rest }) => {
  // const userDetails = JSON.parse(localStorage.getItem('data'))
  const { user } = useSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gotResp, setGotResp] = useState(false);

  useEffect(() => {
    let unmounted = false;
    // make api cal to verify if user is a tenant & has agreed to the data protection policy
    propertyServiceApi()
      .get(`tenant/lid/${user.liveizy_id}?status=resident&populate1=apartment`)
      .then((res) => {
        const records = res.data.payload;
        const apartment_id = localStorage.getItem("RENT_LOAN_APARTMENT");
        const apartment = records.find((record) => record.apartment_id === apartment_id);
        if (
          records.length > 0 &&
          apartment?.data_protection_agreement &&
          (apartment?.monthly_rent_status_stage_1 === NONE ||
            apartment?.monthly_rent_status_stage_1 === REJECTED ||
            apartment?.monthly_rent_status_stage_1 === REVIEW)
        ) {
          if (!unmounted) {
            setIsAuth(true);
            setGotResp(true);
            setIsLoading(false);
          }
        } else {
          if (!unmounted) {
            setGotResp(true);
            setIsLoading(false);
          }
        }
      })
      .catch(function (error) {
        if (!unmounted) {
          setIsAuth(false);
          setGotResp(true);
          setIsLoading(false);
        }
      });

    // Cleanup Function
    return function () {
      unmounted = true;
    };
  }, []);

  // check if user is authenticated first
  if (!window.localStorage.getItem("data")) {
    return <Route {...rest} render={(props) => <Redirect to="/" />} />;
  }

  // set screen to loading while tenant is being verified by api
  if (isLoading) {
    return <CircularProgress />;
  }

  if (gotResp) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuth) {
            return <div>{<Component {...props} />}</div>;
          } else {
            return <Redirect to="/dashboard" />;
          }
        }}
      />
    );
  } else {
    return <Redirect to="/dashboard" />;
  }
};

export const TenantRouter = ({ component: Component, ...rest }) => {
  // const userDetails = JSON.parse(localStorage.getItem('data'))
  const { user } = useSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gotResp, setGotResp] = useState(false);

  useEffect(() => {
    let unmounted = false;
    // make api cal to verify if user is a tenant & has agreed to the data protection policy
    propertyServiceApi()
      .get(`tenant/lid/${user.liveizy_id}?status=resident&populate1=apartment`)
      .then((res) => {
        if (
          res.data.payload[0] &&
          ((res.data.payload[0]?.monthly_rent_status_stage_1 === VERIFIED &&
            res.data.payload[0]?.monthly_rent_status_stage_2 === REVIEW) ||
            (res.data.payload[0]?.monthly_rent_status_stage_1 === VERIFIED &&
              res.data.payload[0]?.monthly_rent_status_stage_2 === NONE))
        ) {
          if (!unmounted) {
            setIsAuth(true);
            setGotResp(true);
            setIsLoading(false);
          }
        } else {
          if (!unmounted) {
            setGotResp(true);
            setIsLoading(false);
          }
        }
      })
      .catch(function (error) {
        if (!unmounted) {
          setIsAuth(false);
          setGotResp(true);
          setIsLoading(false);
        }
      });

    // Cleanup Function
    return function () {
      unmounted = true;
    };
  }, []);

  // check if user is authenticated first
  if (!window.localStorage.getItem("data")) {
    return <Route {...rest} render={(props) => <Redirect to="/" />} />;
  }

  // set screen to loading while tenant is being verified by api
  if (isLoading) {
    return <CircularProgress />;
  }

  if (gotResp) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuth) {
            return <div>{<Component {...props} />}</div>;
          } else {
            return <Redirect to="/dashboard" />;
          }
        }}
      />
    );
  } else {
    return <Redirect to="/dashboard" />;
  }
};
