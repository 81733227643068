import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MonthlyRentStatusActive from "./MonthlyRentStatusActive";
import MonthlyRentStatusComponent from "./MonthlyRentStatusComponent";
import FlexColumnMediaQuery from "#/components/flexboxes/FlexColumnMediaQuery";
import FlexCenter from "#/components/flexboxes/FlexCenter";
import {
  generateVirtualRentWallet,
  selectLoadingWallet,
  selectRentWallet
} from "#/store/actions/izypay.actions";

const VERIFIED = "verified";

const MonthlyRentStatus = ({
  details,
  view,
  statusAction,
  data,
  apartmentID,
  monthlyRentDetails
}) => {
  const dispatch = useDispatch();
  const rentWallet = useSelector(selectRentWallet);
  const loadingWallet = useSelector(selectLoadingWallet);
  const liveizyId = useSelector((state) => state.auth.user.liveizy_id);

  const lastInvoice = data?.data?.[data?.data?.length - 1];

  const { monthly_rent_status_stage_1: stageOne, laid } = details || {};

  useEffect(() => {
    let generateSubscription;

    if (stageOne === VERIFIED && laid && !loadingWallet && !rentWallet) {
      generateSubscription = dispatch(generateVirtualRentWallet(liveizyId));
    }

    return () => {
      if (generateSubscription && generateSubscription.cancel) {
        generateSubscription.cancel();
      }
    };
  }, [stageOne, laid, rentWallet, liveizyId, dispatch, loadingWallet]);

  const monthlyRentStatus = () => {
    if (statusAction === false) {
      return <MonthlyRentStatusActive data={data} statusAction={false} />;
    }
    return null;
  };

  return (
    <>
      {data?.data?.length > 0 && lastInvoice?.payment_status === "processing" ? (
        monthlyRentStatus()
      ) : (
        <FlexColumnMediaQuery sx={{ gap: "1rem" }}>
          {view !== "tenant" && monthlyRentDetails.length > 0 && (
            <FlexCenter style={{ width: "100%" }}>
              <MonthlyRentStatusComponent
                view={view}
                details={details}
                apartmentId={apartmentID}
              />
            </FlexCenter>
          )}
          {/* {tenantRecord.length > 0 && (
            <FlexCenter style={{ flex: "1", width: "100%" }}>
              <RentWallet />
            </FlexCenter>
          )} */}
        </FlexColumnMediaQuery>
      )}
    </>
  );
};

export default MonthlyRentStatus;
