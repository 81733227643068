import { makeStyles } from "@material-ui/core/styles";

export const Styles = makeStyles((theme) => ({
  root: {
    // marginLeft: "20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px"
    },

    "& .wrapper": {
      position: "relative",
      display: "flex",
      borderRadius: "10px",

      "@media(max-width:999px)": {
        backgroundColor: "unset"
      }
    }
  },

  settingsTab: {
    background: "#EEF4FB",
    width: "300px",
    backgroundColor: "#EEF4FB",

    "@media(min-width:999.01px)": {
      height: "100vh",
      marginLeft: "-20px !important",
      marginBottom: "-20px !important",
      position: "sticky",
      top: 0,
      background:
        "linear-gradient(47.51deg, #052137 0%, rgba(13, 46, 72, 0.88) 53.57%, rgba(5, 33, 55, 0.78) 99.68%, rgba(7, 36, 59, 0.827247) 100%)",
      marginTop: "-100px !important"
    },

    "@media(max-width:999px)": {
      position: "absolute",
      width: "100%",
      backgroundColor: "unset",
      height: "100vh"
    },

    "& .settings": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: "50px",

      "@media(max-width:999px)": {
        flexDirection: "row",
        gap: "20px",
        margin: "35% auto",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "50px"
      },

      "&__header": {
        padding: "30px 20px 7px 20px",
        color: "#043B5C",
        fontSize: "0.9em",
        fontWeight: "600",
        fontFamily: "Open Sans-light, sans-serif",
        marginBottom: "20px",
        marginTop: "35px",
        paddingLeft: "30px",
        fontWeight: "600",
        fontSize: "16px",

        "@media(max-width:999px)": {
          display: "none"
        }
      },

      "&__data": {
        padding: "20px 40px",
        color: "#043B5C",
        fontSize: "1em",
        fontWeight: "600",
        cursor: "pointer",
        fontFamily: "Open Sans-Semibold, sans-serif",

        "@media(min-width:999.01px)": {
          color: "#FFFF"
        },

        "&.active": {
          backgroundColor: "#ffff",
          color: "#10609F",
          marginLeft: "30px",
          borderRadius: "8px 0 0 8px",
          padding: "20px 30px"
        },

        "& .icon-wrapper": {
          display: "none",

          "& .icon-size": {
            width: "30px",
            height: "30px"
          },

          "@media(max-width:999px)": {
            display: "block",
            transform: "scale(0.8)",
            color: "#06223968"
          }
        },

        "@media(max-width:999px)": {
          background: "#FFFFFF",
          boxShadow: "0px 1.22243px 8.86262px -2.44486px rgba(0, 0, 0, 0.25)",
          borderRadius: "8px",
          flexDirection: "column-reverse",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "200px",
          minWidth: "130px",
          height: "100px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "20px 40px",
          display: "flex"
        },

        "@media(max-width:400px)": {
          maxWidth: "140px"
        }
      }
    }
  },

  settingDisplay: {
    // padding: "50px",
    padding: "50px",
    alignSelf: "flex-start",
    flex: "1",

    "& .mobile-header": {
      display: "none",

      "@media(max-width:999px)": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 10
      }
    },

    "@media(max-width:999px)": {
      position: "absolute",
      width: "100%",
      margin: 0,
      backgroundColor: "white",
      padding: 0
    },

    "& .setting__display": {}
  }
}));
