import React, { useEffect, useState } from "react";
import { CircularProgress, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApartmentDetails from "./components/ApartmentDetails/ApartmentDetails";
import MonthlyRentBlock from "../components/monthlyrent/MonthlyRentBlock";
import useFetch from "#/hooks/util-hooks/useFetch";
import MonthlyRentStatusActive from "../components/monthlyrent/MonthlyRentStatusActive";
import { getMonthlyRentDetails } from "#/store/actions/tenant.actions";
import LoanRenewalDetails from "#/pages/Dashboard/components/modal/LoanRenewalDetails";
import { useRenderWidgets } from "#/hooks/useRenderWidget";
import { izyBizApi } from "#/apis/propertyServiceApi";
import { selectRentWallet } from "#/store/actions/izypay.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomBtn from "#/components/buttons/CustomBtn";
import SubHeader from "../components/SubHeader";

const TenantDashboard = ({
  renew,
  setRenew,
  view,
  apartmentID,
  liveizyID,
  renewal,
  renewLoading,
  refetch,
  tenantRecord,
  retry,
  setRetry,
  loanRenewModal,
  setLoanRenewModal
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentWallet = useSelector(selectRentWallet);

  const [financeData, setFinanceData] = useState(null);
  const [secondaryFinanceData, setSecondaryFinanceData] = useState(null);
  const [statusAction, setStatusAction] = useState(false);

  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);

  const { data, loading, error } = useFetch(
    apartmentID ? `/invoice/get_apartment_invoice/${apartmentID}` : null
  );

  // const { data: preference, loading: pIsLoading } = useFetch(
  //   liveizyID ? `/bill-preference/${liveizyID}` : null
  // );

  const { data: business, loading: businessIsLoading } = useFetch(
    `/admin/primary-business`,
    izyBizApi
  );

  const { data: apartmentBills, loading: apartmentLoading } = useFetch(
    apartmentID ? `/bill/get_apartment_bills/${apartmentID}` : null
  );

  const lastInvoice = data?.data?.[data?.data?.length - 1];

  const {
    monthly_rent_status_stage_1: stageOne,
    monthly_rent_status_stage_2: stageTwo,
    laid
  } = monthlyRentDetail[0] || {};

  const goToPayment = () => {
    history.push(`/payment-history/${apartmentID}`);
  };

  useEffect(() => {
    if (liveizyID) {
      dispatch(getMonthlyRentDetails(liveizyID));
    }
  }, [liveizyID, dispatch]);

  // Set financeData when business is available
  useEffect(() => {
    if (business?.data?.id) {
      setFinanceData({
        identifier: apartmentID,
        role: "user",
        component: "invoice",
        lid: liveizyID,
        business: business.data.id,
        rentWallet: rentWallet
      });

      setSecondaryFinanceData({
        identifier: apartmentID,
        role: "user",
        component: "",
        lid: liveizyID,
        business: business.data.id,
        stageOne: stageOne,
        stageTwo: stageTwo,
        laid: laid
      });
    }
  }, [business, apartmentID, liveizyID, rentWallet, stageOne, stageTwo, laid]);

  useRenderWidgets(".finance", window.renderFinanceWidget);

  if (loading || renewLoading || apartmentLoading || businessIsLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-20">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading invoices: {error}</div>;
  }

  return (
    <>
      <Container>
        <div
          className="tw-bg-white tw-rounded-2xl tw-py-4 tw-px-10"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
          <ApartmentDetails
            view={view}
            record={tenantRecord}
            details={monthlyRentDetail[0]}
            monthlyRentDetails={monthlyRentDetail}
            setStatusAction={setStatusAction}
            apartmentBills={apartmentBills}
            renew={renew}
            setRenew={setRenew}
            renewal={renewal}
            refetch={refetch}
            retry={retry}
            business={business}
          />
          <MonthlyRentBlock
            view={view}
            details={monthlyRentDetail[0]}
            monthlyRentDetails={monthlyRentDetail}
            data={data}
            apartmentID={apartmentID}
          />
        </div>

        {financeData && (
          <div className="finance" data-finance={JSON.stringify(financeData)} />
        )}

        <SubHeader
          title={"Payments"}
          component={<CustomBtn onClick={goToPayment}>View Payment History</CustomBtn>}
        />

        {secondaryFinanceData && (
          <div className="finance" data-finance={JSON.stringify(secondaryFinanceData)} />
        )}

        {data?.data?.length > 0 &&
          lastInvoice?.payment_status === "processing" &&
          statusAction && <MonthlyRentStatusActive data={data} statusAction={false} />}
      </Container>

      {loanRenewModal && (
        <LoanRenewalDetails
          handleSetModalVisibility={() => setLoanRenewModal(!loanRenewModal)}
          setLoanRenewModal={setLoanRenewModal}
          liveizy_id={liveizyID}
          renewal={renewal}
          setRenew={setRenew}
          setRetry={setRetry}
        />
      )}
    </>
  );
};

export default TenantDashboard;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
`;
