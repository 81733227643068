import React from "react";
import "./body.styles.scss";
import ScrollToTop from "#/components/scrollToTop-component";

const Body = (props) => {
  //This wasn't clicking the full screen. Please check sidebar line 64
  // const mobileToggle = () => {
  //   if (props.isMobile && props.mobileToggle) {
  //     props.onClickHandler();
  //   } else return;
  // };

  return (
    <>
      <div
        id="content"
        style={{ backgroundColor: "#fff" }}
        onClick={() => props.setToggleSideBar(false)}>
        {/* <Header clickToggle={props.onClickHandler} /> */}
        <div className="content-body" style={{ height: "100vh" }}>
          <ScrollToTop />
          {props.children}
        </div>
      </div>
    </>
  );
};

export default Body;
