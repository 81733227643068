import ToggleSwitchWithLabel from "#/components/toggleSwitch/ToggleSwitchWithLabel";
import PaperContainer from "../paperContainer/Index";
import { styled } from "@mui/material";
// import YoutubeLinkIcon from "#/components/link-icons/YoutubeLinkIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "#/constants/routes";
import MonthlyRentStatusActions from "./MonthlyRentStatusActions";
import { useDispatch } from "react-redux";
import useFetch from "#/hooks/util-hooks/useFetch";
import { alertError } from "#/store/actions/notification.actions";

const REJECTED = "rejected";
const REVIEW = "review";
const VERIFIED = "verified";
const ACCEPTED = "accepted";
const PENDING = "pending";
const NONE = "none";

let showRenew = false;
let statusColor;
let statusText;

const MonthlyRentStatusComponent = ({ details, view, data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    monthly_rent_status_stage_1: stageOne,
    monthly_rent_status_stage_2: stageTwo,
    laid,
    review_stage_1,
    is_renew
  } = details || {};

  const { data: renewal, error } = useFetch(
    laid ? `/renewal-status-verification/${laid}` : null
  );

  const monthly_rent_bills = data?.data?.filter((bill) => bill.name === "Monthly Rent");

  if (
    monthly_rent_bills?.length >= 11 && // Check if there are at least 11 bills
    monthly_rent_bills?.every((bill) => bill.status === "paid") // Check if the status of all bills is 'paid'
  ) {
    showRenew = true;
    // Send renew request
    // sendRenewRequest();
  }

  const goToAgreements = () => history.push(APP_ROUTES.dashboard.monthlyRentAgreement);
  const goToActivation = () => history.push(APP_ROUTES.dashboard.monthlyRentActivation);
  const goToForm = () => history.push(APP_ROUTES.dashboard.monthlyRentForm);
  // const goToRenew = () => history.push(APP_ROUTES.dashboard.monthlyRenew);
  const goToRenew = () => {
    renewal?.data?.status
      ? history.push(APP_ROUTES.dashboard.monthlyRenew)
      : dispatch(alertError(error?.response?.data?.message));
  };

  switch (stageOne) {
    case VERIFIED:
      statusColor = "#25CC22";
      statusText = "Pre-qualification (Passed)";
      break;
    case PENDING:
      statusColor = "#FAA82D";
      statusText = "Pre-qualification (In Progress)";
      break;
    case REVIEW:
      statusColor = "#1b4db8";
      statusText = "Pre-qualification (Review)";
      break;
    case REJECTED:
      statusColor = "#FF0000";
      statusText = "Pre-qualification (Failed)";
      break;
    default:
      break;
  }

  switch (stageTwo) {
    case PENDING:
      statusColor = "#1b4db8";
      statusText = "Activation in review";
      break;
    case ACCEPTED:
      statusColor = "#25CC22";
      statusText = "All cleared for activation";
      break;
    default:
      break;
  }

  if (monthly_rent_bills?.length > 0) {
    statusColor = "#25CC22";
    statusText = "Activation Ongoing";
  }

  return (
    <MonthlyRentDiv>
      <Wrapper>
        <ToggleDiv color={statusColor}>
          <ToggleSwitchWithLabel
            color={statusColor}
            checked={true}
            label={"Monthly Rent"}
          />
          <em>{statusText}</em>
        </ToggleDiv>

        {view !== "tenant" && (
          <MonthlyRentStatusActions
            stageOne={stageOne}
            stageTwo={stageTwo}
            review_stage_1={review_stage_1}
            laid={laid}
            goToForm={goToForm}
            goToActivation={goToActivation}
            goToRenew={goToRenew}
            goToAgreements={goToAgreements}
            is_renew={is_renew}
            REVIEW={REVIEW}
            VERIFIED={VERIFIED}
            ACCEPTED={ACCEPTED}
            NONE={NONE}
            view={view}
          />
        )}
      </Wrapper>
    </MonthlyRentDiv>
  );
};

export default MonthlyRentStatusComponent;

const Wrapper = styled(PaperContainer)`
  padding: 1.5rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

const ToggleDiv = styled("div")`
  display: flex;
  flex-direction: column;

  em {
    color: ${(props) => props.color};
    font-size: 0.8rem;
    margin-left: 40px;
  }
`;

const MonthlyRentDiv = styled("div")`
  width: 35% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    width: 100% !important;
  }
`;
