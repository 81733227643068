import { propertyServiceApi } from "#/apis/propertyServiceApi";
import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";

const useFetch = (url, api = propertyServiceApi, options = {}) => {
  const {
    enabled = true,
    method = "GET",
    body,
    retryCount = 0,
    errorMessage: customErrorMessage,
    keepPreviousData = false
  } = options;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const controllerRef = useRef(null);
  const isMountedRef = useRef(false);
  const retryAttempt = useRef(0);

  const fetchData = useCallback(async () => {
    if (!url || !enabled) return;

    // Abort previous request
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    if (!keepPreviousData) {
      setData(null);
    }
    setLoading(true);
    setError(null);

    try {
      const response = await api().request({
        url,
        method,
        data: body,
        signal: controller.signal
      });

      const result = response.data.content ?? response.data;

      if (isMountedRef.current) {
        setData(result);
        retryAttempt.current = 0; // Reset retry counter on success
      }
    } catch (err) {
      if (!isMountedRef.current || axios.isCancel(err)) return;

      // Retry logic
      if (retryCount > 0 && retryAttempt.current < retryCount) {
        retryAttempt.current += 1;
        setTimeout(fetchData, 1000 * retryAttempt.current); // Exponential backoff
        return;
      }

      // Error handling
      const message = customErrorMessage || err.message || "An error occurred";
      setError(message);
    } finally {
      if (isMountedRef.current) {
        setLoading(false);
      }
    }
  }, [url, enabled, method, body, api, retryCount, customErrorMessage, keepPreviousData]);

  // Component mount/unmount handling
  useEffect(() => {
    isMountedRef.current = true;
    if (enabled) fetchData();

    return () => {
      isMountedRef.current = false;
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [fetchData, enabled]);

  const refetch = () => {
    retryAttempt.current = 0;
    fetchData();
  };

  return {
    data,
    loading,
    error,
    refetch,
    isIdle: !enabled && !loading && !error && !data
  };
};

export default useFetch;
