import React, { useState } from "react";
import "../node_modules/metismenujs/dist/metismenujs.css";
import { Route, Switch } from "react-router-dom";

import Sidebar from "#/components/siderbar/sidebar";
import Body from "#/components/layout/body";
import Header from "#/components/layout/header.component";
import { getFromLocalStorage } from "#/components/utils/util";
//import NotFound from "#/components/404.component";

import { PrivateRouter, LandLordRoute, Verified } from "#/routes/PrivateRouter";

/**GENERAL SERVICES */
import Referral from "#/pages/Referral";
import InnerListing from "#/pages/InnerListing/innerListing.component";
import ListingPage from "#/pages/Listing/listing.component";
import Search from "#/pages/Listing/listing.component";

/**WALLET SERVICES */
import WalletDashbord from "#/pages/Wallet/wallet-dashboard.jsx";
import izyvestPopup from "#/pages/Wallet/fund-wallet/izyInvestPopup";
import IzyVest from "#/pages/IzyVest/Izyvest.jsx";

/**PROPERTY SERVICES */
import PropertyDetails from "#/pages/Property/property-details/propertyDetails.jsx";
import PropertyAdvertisement from "#/pages/Property/property-advertisement";
import CreatePropAndApart from "#/pages/Property/createPropAndApart/createPropAndApart.component";
import IncomeDashboard from "#/pages/Property/income/IncomeDashboard";
import IncomeInvoiceDashboard from "#/pages/Property/income/IncomeInvoiceDashboard";
import ApplicationDetailsPage from "#/pages/Property/application/ApplicationDetails";
import Income from "#/pages/Property/income/income";
import tenantsListDashboard from "#/pages/Tenant/tenantsListing/tenantsListDashboard";
import TenantDetailDashboard from "#/pages/Tenant/tenantsListing/tenantDetailDashboard";
import Inspection from "#/pages/Tenant/booking/inspection";
import Status from "#/pages/Tenant/booking/status";
// import NotFound from "#/components/404.component";
import AccountSettings from "#/pages/AccountSettings";
import PropertiesListing from "#/pages/Property/propertyListing";
import EditPropertyTeam from "#/pages/Property/editPropertyTeam/editPropertyTeam";
import PropertyTeam from "#/pages/Property/propertyTeam";
import PropertyApartments from "#/pages/Property/propertyAndApartmentDetails/propertyAndApartmentDetails";

import EditProperty from "#/pages/Property/editProperty/editProperty.component";
import EditApartment from "#/pages/Property/editApartment/editApartment.component";
import Subscription from "#/pages/Property/subscription/subscription";

import Dashboard from "#/pages/Dashboard/Dashboard";
import NewProperties from "./pages/Property/NewProperty/NewProperties";
//--------------
//Just for now
import ShowOfInterestForm from "#/pages/ShowOfInterest";
import Lpms from "./pages/Property/lpms/lpms";
import LipmanPage from "./pages/Property/propertyListing/lipmanPage";
import LiremPage from "./pages/Property/propertyListing/liremPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import ReceiptPage from "./pages/Receipts/ReceiptPage";
import PaymentPageRenewRent from "./pages/Payment/PaymentPageRenewRent";
import PaymentPageCharges from "./pages/Payment/PaymentPageCharges";
import Members from "./estate-management/members";
import Financial from "./estate-management/financial";
import MembersDashboard from "./estate-management/members-dashboard";
import MenuItems from "./components/estateManagementComponents/menu-bar-items";
import Role from "./estate-management/role";
import Payment from "./estate-management/payment";
import Summary from "./estate-management/summary";
import Fee from "./estate-management/fee";
import { APP_ROUTES, ESM_ROUTES } from "./constants/routes";
import PaymentPageFees from "./pages/Payment/estate-management/PaymentPageFees";
import RentLoanForm from "./pages/RentLoanNew/Index";
import PayRent from "./pages/Payment/apartment/PayRent";
import AssociationRoutes from "./association/routes/AssociationRoutes";
import ActivateMonthlyRent from "./dashboard/components/monthlyrent/activation/ActivateMonthlyRent";
import RenewMonthlyRent from "./dashboard/components/monthlyrent/renew/RenewMonthlyRent";
import RentalPayments from "./dashboard/TenantDashboard/components/rentalPayments/RentalPayments";
import MonthlyRentAgreement from "./pages/RentLoanNew/AgreementForm/MonthlyRentAgreement";
import PaymentHistory from "./pages/Payment/paymentHistory";
import IzyMart from "./pages/izymart/index";
import NewTenantInfo from "./pages/Tenant/NewTenantInfo/NewTenantInfo";
import NewTenantDetails from "./pages/Tenant/tenants/NewTenantDetails";
// import Listings from "./pages/Tenant/listings/listings";

//--------------
export const DashboardRoutes = () => {
  const [toggleSideBar, setToggleSideBar] = useState(false);

  const [toggle, setToggle] = useState(() => getFromLocalStorage("bodyState") || "full");
  const [mobileToggle, setMobileToggle] = useState(false);

  return (
    <Switch>
      {/*---------------- Estate Management --------------------------*/}
      <Route
        path={[
          ESM_ROUTES.dashboard,
          ESM_ROUTES.members,
          ESM_ROUTES.roles,
          ESM_ROUTES.finance,
          ESM_ROUTES.payments,
          ESM_ROUTES.summary,
          ESM_ROUTES.fee
        ]}
        exact>
        <MenuItems>
          <Switch>
            <Route exact path={ESM_ROUTES.dashboard} component={MembersDashboard} />
            <Route exact path={ESM_ROUTES.members} component={Members} />
            <Route exact path={ESM_ROUTES.roles} component={Role} />
            <Route exact path={ESM_ROUTES.finance} component={Financial} />
            <Route exact path={ESM_ROUTES.payments} component={Payment} />
            <Route exact path={ESM_ROUTES.summary} component={Summary} />
            <Route exact path={ESM_ROUTES.fee} component={Fee} />
          </Switch>
        </MenuItems>
      </Route>

      <Route exact path="/payments" component={PaymentPage} />
      <Route exact path="/payments/renew-rent" component={PaymentPageRenewRent} />
      <Route exact path="/payments/charges" component={PaymentPageCharges} />
      <Route exact path={ESM_ROUTES.payFees} component={PaymentPageFees} />
      <Route exact path="/receipts/:receipt_id" component={ReceiptPage} />
      <div className="wrapper">
        <Sidebar
          toggle={toggle}
          mobile={mobileToggle}
          setMobileToggle={setMobileToggle}
          sideBarToggled={toggleSideBar}
          setToggleSideBar={setToggleSideBar}
        />
        <Body toggleSideBar={toggleSideBar} setToggleSideBar={setToggleSideBar}>
          <Header setToggleSideBar={setToggleSideBar} />
          <Switch>
            <PrivateRouter path="/association" component={AssociationRoutes} />
            <PrivateRouter exact path="/dashboard" component={Dashboard} />
            <PrivateRouter exact path="/new-property" component={NewProperties} />
            <PrivateRouter
              exact
              path="/tenant-information"
              component={NewTenantDetails}
            />
            {/* <PrivateRouter
              exact
              path="/monthlyrent-apply"
              component={MonthlyRentApplyForm}
            /> */}
            <PrivateRouter
              exact
              path="/payments/rent/:apartment_id"
              component={PayRent}
            />
            <PrivateRouter
              exact
              path={APP_ROUTES.dashboard.monthlyRentForm}
              component={RentLoanForm}
            />
            <PrivateRouter
              exact
              path={APP_ROUTES.dashboard.monthlyRentAgreement}
              component={MonthlyRentAgreement}
            />
            <PrivateRouter
              exact
              path={APP_ROUTES.dashboard.monthlyRentActivation}
              component={ActivateMonthlyRent}
            />
            <PrivateRouter
              exact
              path={APP_ROUTES.dashboard.monthlyRenew}
              component={RenewMonthlyRent}
            />
            <PrivateRouter
              exact
              path={APP_ROUTES.dashboard.rentalpayments}
              component={RentalPayments}
            />
            <LandLordRoute
              exact
              path="/tenants/booking/inspection"
              component={Inspection}
            />
            <LandLordRoute exact path="/tenants/booking/status" component={Status} />
            <PrivateRouter exact path="/referral" component={Referral} />
            {/* <PrivateRouter exact path="/tenants/listings" component={Listings} /> */}
            <PrivateRouter exact path="/wallet" component={WalletDashbord} />
            <PrivateRouter exact path="/izymart" component={IzyMart} />
            {/* <PrivateRouter exact path="/account-setting" component={AccSetting} /> */}
            <PrivateRouter path="/account-setting" component={AccountSettings} />
            <PrivateRouter exact path="/inner-listing" component={InnerListing} />
            <PrivateRouter
              exact
              path="/payment-history/:apartment_id"
              component={PaymentHistory}
            />
            {/* <PrivateRouter path="/account-setting" component={AccountSettings} /> */}
            <PrivateRouter
              exact
              path="/inner-listing/:apartment_id"
              component={InnerListing}
            />
            <PrivateRouter
              exact
              path="/booking/show-of-interest/:bookingId"
              component={ShowOfInterestForm}
            />
            <PrivateRouter exact path="/listingpage" component={ListingPage} />
            <LandLordRoute exact path="/income" component={Income} />
            <Verified exact path="/izyinvest" component={IzyVest} />
            <LandLordRoute
              exact
              path="/income/invoice"
              component={IncomeInvoiceDashboard}
            />
            <LandLordRoute exact path="/income/dashboard" component={IncomeDashboard} />
            <PrivateRouter exact path="/listing" component={Search} />
            <LandLordRoute exact path="/activateplan" component={izyvestPopup} />
            <LandLordRoute
              exact
              path="/tenant/detail"
              component={TenantDetailDashboard}
            />
            †
            <LandLordRoute exact path="/tenants/lists" component={tenantsListDashboard} />
            <LandLordRoute exact path="/tenants/list/new" component={NewTenantInfo} />
            <LandLordRoute
              exact
              path="/apartment-edit/:propertyId/:apartmentId"
              component={EditApartment}
            />
            <LandLordRoute
              exact
              path="/property-edit/:propertyId"
              component={EditProperty}
            />
            <LandLordRoute path="/create/:id" component={CreatePropAndApart} />
            {/* <LandLordRoute path="/bill-settings/:apartmentId" component={BillSettings} /> */}
            <LandLordRoute exact path="/property-details" component={PropertyDetails} />
            <LandLordRoute
              exact
              path="/property-advert"
              component={PropertyAdvertisement}
            />
            <LandLordRoute
              exact
              path="/property-list/property-apartment/:property_id"
              component={PropertyApartments}
            />
            <LandLordRoute exact path="/property/list" component={PropertiesListing} />
            <LandLordRoute exact path="/property/list/new" component={NewProperties} />
            <LandLordRoute exact path="/property/plan/lipman" component={LipmanPage} />
            <LandLordRoute exact path="/property/plan/lirem" component={LiremPage} />
            <LandLordRoute path="/property/subscription" component={Subscription} />
            <LandLordRoute path="/property/members" component={PropertyTeam} />
            <LandLordRoute path="/property/members/edit" component={EditPropertyTeam} />
            <LandLordRoute
              path="/property/application"
              component={ApplicationDetailsPage}
            />
            <LandLordRoute path="/property/lpms" component={Lpms} />
          </Switch>
        </Body>
      </div>
    </Switch>
  );
};
