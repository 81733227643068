import Modal from "#/components/modal/modal.component";
import useFetch from "#/hooks/util-hooks/useFetch";
import { Button } from "@mui/material";
import { FaTimes } from "react-icons/fa";

const LoanRenewalDetails = ({
  renewal,
  handleSetModalVisibility,
  liveizy_id,
  setRenew,
  setRetry,
  setLoanRenewModal
}) => {
  const { data: currentRenewal, loading } = useFetch(`/current-renewal/${liveizy_id}`, {
    errorMessage: "Error getting data"
  });

  const { is_applied } = renewal?.data;

  if (loading) return <p>Loading...</p>;
  if (!currentRenewal) return <p>No data available.</p>;

  const {
    current_rent_value,
    securityDeposit,
    contributionFee,
    adminComments,
    acceptanceStatus,
    is_new_apartment
  } = currentRenewal.data || {};

  const handleRenew = () => {
    setLoanRenewModal(false);
    setRenew(true);
    setRetry(true);
  };

  const renderBody = () => (
    <>
      <div
        className="tw-pt-5 tw-pr-5 tw-flex tw-justify-end"
        onClick={handleSetModalVisibility}>
        <FaTimes color="#10609F" size={25} className="tw-cursor-pointer" />
      </div>
      <div className="content-center">
        <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>
          <span className="tw-text-[1.7rem] tw-font-semibold">
            Monthly Rent Renewal Details
          </span>
        </h2>

        <div className="tw-mt-16">
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">New Apartment</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {is_new_apartment ? `Yes` : "No"}
            </h2>
          </div>
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">Monthly Rent Amount</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {current_rent_value
                ? `₦${Number(current_rent_value).toLocaleString()}`
                : "₦0"}
            </h2>
          </div>
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">Refundable Security Deposit</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {securityDeposit ? `₦${Number(securityDeposit).toLocaleString()}` : "₦0"}
            </h2>
          </div>
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">Contribution Fee</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {contributionFee ? `₦${Number(contributionFee).toLocaleString()}` : "₦0"}
            </h2>
          </div>
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">Renewal Acceptance Status</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {acceptanceStatus ? `Approved` : "Not approved"}
            </h2>
          </div>
          <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol tw-border">
            <p className="tw-text-[#052137]">Admin Comment</p>
            <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
              {adminComments || "No comment yet"}
            </h2>
          </div>

          {is_applied === "rejected" && (
            <div className="tw-mt-10 tw-text-center">
              <Button variant="contained" color="error" onClick={handleRenew}>
                Start Renewal Process Again
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <Modal
      isModalOpen={true}
      handleCloseModal={handleSetModalVisibility}
      hasHeader={false}
      style={{ maxWidth: "50rem", borderRadius: 10 }}>
      {renderBody()}
    </Modal>
  );
};

export default LoanRenewalDetails;
