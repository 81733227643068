/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "#/components/popup/popup";
import EmailAlert from "#/components/emailAlert";
import PhoneNumberAlert from "#/components/dashboard/phoneNumberAlert/phoneNumberAlert";
import { useDashboardStyles } from "./Dashboard.styles";
import GeneralUserDashboard from "#/dashboard/generalUserDashboard/GeneralUserDashboard";
import { getTenantsApartment } from "#/store/actions/tenant.actions";
import TenantDashboard from "#/dashboard/TenantDashboard/TenantDashboard";
import IzypayIzyinvestSection from "#/dashboard/components/IzypayIzyinvest/IzypayIzyinvestSection";
import PropertyDashboard from "./PropertyDashboard";
import useFetch from "#/hooks/util-hooks/useFetch";
import RenewalAlert from "#/components/RenewalAlert";
import { CircularProgress } from "@material-ui/core";
import ShowCalculator from "./components/dashboard-showcase/components/monthlyRateModal/ShowCalculator";
import { izyBizApi } from "#/apis/propertyServiceApi";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const classes = useDashboardStyles();
  const [dashboardView, setDashboardView] = useState("property");
  const [showEmailAlert, setShowEmailAlert] = useState(true);
  const [showRenewalAlert, setShowRenewalAlert] = useState(true);
  const [renewModal, setRenewModal] = useState(false);
  const [loanRenewModal, setLoanRenewModal] = useState(false);
  const [retry, setRetry] = useState(false);

  const rolesPopup = useSelector((state) => state.roles.popup);
  const user = useSelector((state) => state.auth.user);
  const { is_email_verified, roles, liveizy_id, email } = user;

  const { data: tenant, loading } = useFetch(`tenant/${liveizy_id}`, izyBizApi);

  const apartmentID = tenant?.data?.apartment_tenant?.[0]?.apartment_id;
  const isLandlord = roles.includes("Landlord");
  const isAgent = roles.includes("Agent");
  const isTenant = roles.includes("Tenant") || tenant?.data;
  const isLandlordAndPm = isLandlord && isAgent;
  const isLandlordOrPm = isLandlord || isAgent;
  const isGeneralUser = roles.length < 1 && !tenant?.data;
  const showTab = isTenant && (isLandlordAndPm || isLandlordOrPm);

  const { data: renewal, refetch, loading: renewLoading } = useFetch(
    apartmentID ? `/renewal-status/${apartmentID}` : null
  );

  useEffect(() => {
    if (isTenant && liveizy_id) {
      dispatch(getTenantsApartment(liveizy_id));
    }
  }, [liveizy_id, dispatch, isTenant]);

  useEffect(() => {
    if (isTenant && !(isLandlordAndPm || isLandlordOrPm)) {
      setDashboardView("tenant"); // For tenants only
    }
    if (isLandlordAndPm || isLandlordOrPm) {
      setDashboardView("property"); // For PMs and/or landlords only
    }
  }, [isLandlordAndPm, isLandlordOrPm, isTenant, tenant?.data.length]);

  useEffect(() => {
    setShowEmailAlert(!is_email_verified);
  }, [is_email_verified]);

  if (!user || loading || renewLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-20">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Popup roles={rolesPopup} />
      {showEmailAlert && <EmailAlert email={email} />}
      {showRenewalAlert && (
        <RenewalAlert
          onClose={() => setShowRenewalAlert(false)}
          setRenew={setRenewModal}
          renewal={renewal}
          setShowRenewalAlert={setShowRenewalAlert}
          setLoanRenewModal={setLoanRenewModal}
        />
      )}
      <PhoneNumberAlert />
      <IzypayIzyinvestSection />
      <ShowCalculator isTenant={false} />
      {showTab && (
        <section>
          <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-my-14">
            <ul className="tw-rounded-2xl my-shadow tw-flex tw-transition-all tw-duration-100">
              <button
                onClick={() => setDashboardView("property")}
                className={`${
                  dashboardView === "property"
                    ? "tw-bg-[#10609F] tw-text-white"
                    : "tw-bg-white tw-text-[#10609F]"
                } tw-px-8 tw-py-4 tw-rounded-2xl tw-cursor-pointer tw-font-semibold tw-transition-all tw-duration-100 text-link`}>
                PROPERTY DASHBOARD
              </button>
              <button
                onClick={() => setDashboardView("tenant")}
                className={`${
                  dashboardView === "tenant"
                    ? "tw-bg-[#10609F] tw-text-white"
                    : "bg-white tw-text-[#10609F]"
                } tw-px-8 tw-py-4 tw-rounded-2xl tw-cursor-pointer tw-font-semibold tw-transition-all tw-duration-100 text-link`}>
                TENANT DASHBOARD
              </button>
            </ul>
          </div>
        </section>
      )}
      {isGeneralUser && (
        <GeneralUserDashboard view={dashboardView} liveizyID={liveizy_id} />
      )}
      {dashboardView === "tenant" && (
        <TenantDashboard
          renew={renewModal}
          user={user}
          setRenew={setRenewModal}
          view={dashboardView}
          apartmentID={apartmentID}
          liveizyID={liveizy_id}
          renewal={renewal}
          refetch={refetch}
          retry={retry}
          tenantRecord={tenant?.data}
          renewLoading={renewLoading}
          loanRenewModal={loanRenewModal}
          setLoanRenewModal={setLoanRenewModal}
          setRetry={setRetry}
        />
      )}
      {dashboardView === "property" && (isLandlord || isAgent) && (
        <PropertyDashboard view={dashboardView} liveizyID={liveizy_id} />
      )}
    </div>
  );
};

export default Dashboard;
